<template>
  <div>
    <v-card class="ma-2">
      <div class="d-flex justify-space-between px-4">
        <v-card-title>Users</v-card-title>
        <v-card-title>
          <v-btn
              @click="newUser()"
              color="primary"
          >add user
          </v-btn>
        </v-card-title>
      </div>
    </v-card>
    <v-card class="ma-2 pa-4">
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="userList"
            hide-default-footer
        >
          <template v-slot:item.isDeleted="{item}">
            <v-icon
                @click="toggleUserActivation(item.id)"
                class="mr-4 cursor-pointer"
                style="color: #ff9800"
            >
              {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
            </v-icon>
            <v-icon @click="getUser(item.id)"
                    class="cursor-pointer mr-4"
                    style="color: #397dfb">mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- <v-dialoge> -->
    <v-dialog v-model="openEdit" max-width="500px">
      <v-card>
        <v-card-title v-if="update">Create user</v-card-title>
        <v-card-title v-else>Edit user</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    label="fio"
                    outlined
                    v-model="User.fio"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    v-model="User.roleId"
                    :items="selectItem"
                    item-value="id"
                    item-text="name"
                    class="ma-0 pa-0"
                    solo style="width: 500px"
                    label="Role"
                    transition="scale-transition"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="username"
                    outlined
                    v-model="User.username"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="password"
                    outlined
                    v-model="User.password"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col>
                <v-text-field
                  label="roleId"
                  outlined
                  v-model="User.roleId"
                >
                </v-text-field>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="openEdit = false" style="width: 100%" color="error">
                  Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="update" @click="addUser()" style="width: 100%" color="success">
                  save
                </v-btn>
                <v-btn v-else @click="EditUser()" dark style="width: 100%" color="orange">
                  Edit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- </v-dialoge> -->

  </div>
</template>

<script>
export default {
  name: 'UserPage',
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false},
        {text: 'Fio', value: 'fio', align: 'center', sortable: false},
        {text: 'Role Name', value: 'roleName', align: 'center', sortable: false},
        {text: 'User name', value: 'username', align: 'center', sortable: false},
        {text: 'IsDeleted', value: 'isDeleted', align: 'center', sortable: false},
      ],
      openEdit: false,
      update: false,

      selectItem: [],
      roleSelect: '',

      userList: [],
      User: {
        id: 0,
        fio: '',
        username: '',
        password: '',
        roleId: 1,
      },
    }
  },
  methods: {
    newUser() {
      this.update = true,
          this.openEdit = true,
          this.emptyuser()
    },
    emptyuser() {
      this.User.fio = ''
      this.User.username = ''
    },

    async addUser() {
      try {
        const res = await this.$http.post('User/upsertUser', this.User);
        if (!res.error) {
          console.log(res.result);
          this.userList = res.result.users
          this.openEdit = false
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    EditUser() {
      this.upsertUser(),
          this.openEdit = false
    },
    async upsertUser() {
      try {
        const res = await this.$http.post('User/upsertUser', this.User);
        if (!res.error) {
          // console.log(res.result.users);
          this.userList = res.result.users
          this.successNotification('Успешно')
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async getUser(id) {
      this.update = false
      try {
        const res = await this.$http.get(`User/getUser?userId=${id}`);
        if (!res.error) {
          // console.log(res.result);
          this.User = res.result
          this.openEdit = true
        }
      } catch {

      }
    },
    async toggleUserActivation(id) {
      try {
        const res = await this.$http.get(`User/toggleUserActivation?userId=${id}`);
        if (!res.error) {
          // console.log(res.result);
          // this.successNotification();
          this.userList = res.result.users
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async getUsers() {
      try {
        const res = await this.$http.get('User/getUsers');
        if (!res.error) {
          // console.log(res.result.users);
          this.userList = res.result.users
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
  },
  created() {
    this.getUsers();
    this.$http.get(`Role/getRoles`).then((res) => {
      if (res && res.result && res.result.roles && res.result.roles.length > 0) {
        // console.log(res.result.roles);
        this.selectItem = res.result.roles
        this.roleSelect = res.result.roles[0].id
        // this.getList()
      }
    })
        .catch((e) => {
          console.log(e)
        });

  },
}
</script>
